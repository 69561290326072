import { OctraNavigation } from '@core/types/octra-navigation.type';
import { environment } from '@environments/environment';
import { APP_ROUTES } from '@environments/routes/app-routes';
import { ClientModules } from '@shared/clients/types/client-modules.enum';
import { ClientViewPrivilegesEnum } from '@shared/clients/types/client-privileges.enum';

const nav: OctraNavigation[] = [
  {
    label: 'Accounts',
    clientCond: 'withSelectedClient',
    route: (params) => APP_ROUTES.accountsV1.overview(params.currentClientSlug)
  },
  {
    label: 'Governance',
    clientCond: 'withSelectedClient',
    items: [
      {
        label: 'Best practices',
        route: (params) => APP_ROUTES.governance.bestPractices(params.currentClientSlug),
        privileges: [
          ClientViewPrivilegesEnum.StatsView,
          ClientViewPrivilegesEnum.OpportunitiesView,
          ClientViewPrivilegesEnum.GovernanceView
        ]
      },
      {
        label: 'Anomaly detection',
        route: (params) => APP_ROUTES.governance.anomalyDetection(params.currentClientSlug),
        requiredModule: ClientModules.ANOMALY_DETECTION
      },
      {
        label: 'QA',
        route: (params) => APP_ROUTES.governance.qaChecklists.list(params.currentClientSlug)
      }
    ]
  },
  {
    label: 'Taxonomy',
    clientCond: 'withSelectedClient',
    items: [
      {
        label: 'Naming conventions',
        route: (params) => APP_ROUTES.governance.namingConventions(params.currentClientSlug),
        requiredModule: [ClientModules.CUSTOM_NC, ClientModules.TEMPLATES_NC],
        privileges: [
          ClientViewPrivilegesEnum.StatsView,
          ClientViewPrivilegesEnum.OpportunitiesView,
          ClientViewPrivilegesEnum.GovernanceView
        ],
        clientsNotVisible: environment.production ? ['nestle'] : []
      },
      {
        label: 'Value tables',
        route: (params) => APP_ROUTES.client.tables(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.TablesView
      },
      {
        label: 'Submissions',
        route: (params) => APP_ROUTES.client.allSubmissions(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.DatabaseView
      }
    ]
  },
  {
    label: 'Client',
    clientCond: 'withSelectedClient',
    items: [
      {
        label: 'Settings',
        route: (params) => APP_ROUTES.client.general(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.ClientView
      },
      {
        label: 'Business structure',
        route: (params) => APP_ROUTES.client.businessStructure(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.BSView
      },
      {
        label: 'Users',
        route: (params) => APP_ROUTES.client.users(params.currentClientSlug),
        privileges: ClientViewPrivilegesEnum.UsersView
      }
    ]
  }
];

if (!environment.production) {
  nav.push({
    label: 'Dev',
    items: [
      {
        label: 'Css utils',
        route: () => ['/dev/css-utils']
      },
      {
        label: 'Loader',
        route: () => ['/dev/loader']
      },
      {
        label: 'Octra UI components',
        route: () => ['/dev/octra-styling']
      },
      {
        label: 'NgxTable styling',
        route: () => ['/dev/ngx-table-styling']
      },
      {
        label: 'NgxTable directive',
        route: () => ['/dev/ngx-table-directive']
      },
      {
        label: 'NgxTable footers',
        route: () => ['/dev/ngx-table-footers']
      },
      {
        label: 'NgxTable selection',
        route: () => ['/dev/ngx-table-selection']
      },
      {
        label: 'NgxTable column & width',
        route: () => ['/dev/ngx-table-width']
      },
      {
        label: 'Meta business auth',
        route: () => ['/dev/meta-business-auth']
      }
    ]
  });
}

export const sideNavigation: OctraNavigation[] = nav;
